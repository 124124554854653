<template>
  <b-container class="overflow-auto" fluid>
    <!-- Ad Interface controls -->
    <h5 class="my-3"><strong>REMOTE RTB ZONES</strong></h5>
    <a-row class="my-2" :gutter="[8]">
      <a-col :xl="3" :md="6" :xs="12">
        <a-checkbox v-model="showActive">
          Show only active
        </a-checkbox>
      </a-col>
      <a-col :xl="4" :md="12" :xs="24">
        <a-select allowClear placeholder="Advertiser" style="width: 100%" :showSearch="true" :filterOption="true"
          optionFilterProp="children" v-model="selectedAdvertiser" @change="getFilteredZones">
          <a-select-option v-for="obj in advertiser.list" :key="obj.Id">[{{ obj.Id }}] {{ obj.Name
            }}</a-select-option>
        </a-select>
      </a-col>
      <a-col :xl="4" :md="12" :xs="24">
        <a-select allowClear placeholder="Account Manager" style="width: 100%" :showSearch="true" :filterOption="true"
          optionFilterProp="children" v-model="selectedUser" @change="getFilteredZones">
          <a-select-option v-for="obj in user.list" :key="obj.Id">[{{ obj.Id }}] {{ obj.FirstName }} {{ obj.LastName
            }}</a-select-option>
        </a-select>
      </a-col>
    </a-row>
    <b-row>
      <b-col class="my-1" md="6">
        <b-form-group class="mb-0" label="Per page:" label-cols-sm="2">
          <perPageSelect v-model="perPage" :storageKey="'ortb-remote-rtb-zones'" :pageOptions="pageOptions" />
          <!-- <b-form-select v-model="perPage" :options="pageOptions" class="form-control ml-0 w-25"></b-form-select> -->
          <columnSelect :storageKey="'display-remotertbzones-columns'" :options="fields"></columnSelect>
          <CreateButton   target="remotertbzone" />
        </b-form-group>
      </b-col>
      <b-col class="my-1 d-flex flex-row-reverse" md="6">
        <b-form-group class="mb-0">
          <b-input-group>
            <b-form-input v-model="filter" class="form-control w-50" placeholder="Type to Search"></b-form-input>
          </b-input-group>
        </b-form-group>
      </b-col>
    </b-row>

    <!-- Main table element -->
    <b-table :bordered="true" :current-page="currentPage" :fields="filteredFields" :filter="filter" :hover="true"
      :items="showRemoteRTBZones" :per-page="perPage" :small="true" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc"
      :sort-compare="sortCompare" :sort-direction="sortDirection" :striped="true" :tbody-tr-class="rowClass" show-empty
      stacked="md" @filtered="onFiltered">
      <template #cell(Name)="data">
        <div class="items-center">
          {{ data.item.Name }}
        </div>
      </template>
      <template #cell(AdvertiserName)="data">
        <!-- SM screen -->
        <div class="d-block d-sm-none">
          <!-- name slot -->
          <div class="items-center">
            <div v-if="data.item.AdvertiserName.length <= 20">{{ data.item.AdvertiserName }}</div>
            <div class="text-left" style="max-width: 200px; min-width: 140px; word-wrap: break-word;"
              v-if="data.item.AdvertiserName.length > 20">{{ data.item.AdvertiserName }}
            </div>
          </div>
        </div>
        <!-- * EXCEPT SM screen -->
        <div class="d-none d-sm-block">
          <!-- name slot -->
          <div class="items-center">
            <div data-toggle="tooltip" data-placement="top" :title="data.item.AdvertiserName" class="">
              {{ data.item.AdvertiserName }}
            </div>
          </div>
        </div>
      </template>
      <template #cell(Popfeeds)="data">
        <div v-if="data.item.Popfeeds.length > 0">
          <a-tooltip slot="addonBefore" trigger="click" :overlayStyle="{ 'max-width': '200%' }">
            <template slot="title">
              <div className="zone-list">
                <div :key="feed.Id" v-for="feed in popfeeds">
                  {{
      data.item.Popfeeds.includes(feed.Id) ? `[${feed.Id}] ${feed.Name}` : ''
    }}
                </div>
              </div>
            </template>
            <a-button type="primary">
              {{ data.item.Popfeeds.length }}
            </a-button>
          </a-tooltip>
        </div>
      </template>
      <template #cell(Rtbzones)="data">
        <div v-if="data.item.Rtbzones.length > 0">
          <a-tooltip slot="addonBefore" trigger="click" :overlayStyle="{ 'max-width': '200%' }">
            <template slot="title">
              <div className="zone-list">
                <div :key="zone.Id" v-for="zone in rtbzones">
                  {{
      data.item.Rtbzones.includes(zone.Id) ? `[${zone.Id}] ${zone.Name}` : ''
    }}
                </div>
              </div>
            </template>
            <a-button type="primary">
              {{ data.item.Rtbzones.length }}
            </a-button>
          </a-tooltip>
        </div>
      </template>
      <template #cell(Videozones)="data">
        <div v-if="data.item.Videozones.length > 0">
          <a-tooltip slot="addonBefore" trigger="click" :overlayStyle="{ 'max-width': '200%' }">
            <template slot="title">
              <div className="zone-list">
                <div :key="zone.Id" v-for="zone in videozones">
                  {{
      data.item.Videozones.includes(zone.Id) ? `[${zone.Id}] ${zone.Name}` : ''
    }}
                </div>
              </div>
            </template>
            <a-button type="primary">
              {{ data.item.Videozones.length }}
            </a-button>
          </a-tooltip>
        </div>
      </template>
      <template #cell(Bannerzones)="data">
        <div v-if="data.item.Bannerzones.length > 0">
          <a-tooltip slot="addonBefore" trigger="click" :overlayStyle="{ 'max-width': '200%' }">
            <template slot="title">
              <div className="zone-list">
                <div :key="zone.Id" v-for="zone in bannerzones">
                  {{
      data.item.Bannerzones.includes(zone.Id) ? `[${zone.Id}] ${zone.Name}` : ''
    }}
                </div>
              </div>
            </template>
            <a-button type="primary">
              {{ data.item.Bannerzones.length }}
            </a-button>
          </a-tooltip>
        </div>
      </template>
      <template #cell(AvgRequestTimeMs)="data">
        {{ data.item.AvgRequestTimeMs }} ms
      </template>
      <template #cell(MaxIvt)="data">
        {{ data.item.MaxIvt }}%
      </template>
      <template #cell(MaxSivt)="data">
        {{ data.item.MaxSivt }}%
      </template>
      <template #cell(MinTmax)="data">
        {{ data.item.MinTmax }} ms
      </template>
      <template #cell(ImpressionTrackingMethod)="data">
        {{ impressionTrackingMethod[data.item.ImpressionTrackingMethod] }}
      </template>
      <template #cell(BannerEnabled)="data">
        {{ data.item.BannerEnabled === 1 ? '✔' : '' }}
      </template>
      <template #cell(VideoEnabled)="data">
        {{ data.item.VideoEnabled === 1 ? '✔' : '' }}
      </template>
      <template #cell(AudioEnabled)="data">
        {{ data.item.AudioEnabled === 1 ? '✔' : '' }}
      </template>
      <template #cell(NativeEnabled)="data">
        {{ data.item.NativeEnabled === 1 ? '✔' : '' }}
      </template>
      <template #cell(PopEnabled)="data">
        {{ data.item.PopEnabled === 1 ? '✔' : '' }}
      </template>
      <template #cell(AutoOptimize)="data">
        {{ data.item.AutoOptimize === 1 ? '✔' : '' }}
      </template>
      <template #cell(WebEnabled)="data">
        {{ data.item.WebEnabled === 1 ? '✔' : '' }}
      </template>
      <template #cell(AppEnabled)="data">
        {{ data.item.AppEnabled === 1 ? '✔' : '' }}
      </template>
      <template #cell(StatusString)="data">
        <a-checkbox :checked="(data.item.Status == 0)" @change="toggleRemoteRTBZoneStatus(data.item)">
        </a-checkbox>
      </template>
      <template #cell(SeatListMode)="data">
        {{ data.item.SeatListMode === 0 ? 'Blacklist' : 'Whitelist' }}
      </template>
      <template #cell(actions)="data">
        <div class="flex">
          <b-button @click="showModal(data.item)" class="btn-info mr-1" size="sm">
            <i class="fa fa-info"></i>
          </b-button>
          <router-link class="add-button" @click.stop
            :to="{ name: 'remotertbzone-update', params: { id: data.item.Id }, }">
            <b-button class="btn-info mr-1" size="sm" @click="editRemoteRTBZone(data.item.Id)">
              <i class="fa fa-edit"></i>
            </b-button>
          </router-link>
          <b-button class="btn-info mr-1" size="sm" @click="copyRemoteRTBZone(data.item.Id, data.item)">
            <i class="fa fa-copy"></i>
          </b-button>
        </div>
      </template>
    </b-table>
    <b-modal id="bv-modal-example" hide-footer size="lg" body-class="rtb-modal" class="rtb-modal">
      <template #modal-title>
        Remote RTB Zone Info
      </template>
      <div class="d-block" style="min-height: 500px">
        <div>
          <a-tabs default-active-key="1" class="mb-5">
            <a-tab-pane key="1" tab="Advertiser Reports">
              <div class="card mb-2">
                <div class="card-header p-3" style="background-color: rgba(237, 237, 243, 0.38)">
                  By Date
                </div>
                <div class="card-body p-3">
                  <a-input-search :value="modal.reportsByDate" placeholder="" @focus="selectUrl"
                    @change="$event.target.value = modal.reportsByDate" @search="copyUrl">
                    <template #enterButton>
                      <a-button @click="copyUrl(modal.reportsByDate)"><i class="fa fa-copy"></i></a-button>
                    </template>
                  </a-input-search>
                </div>
              </div>
            </a-tab-pane>
          </a-tabs>
        </div>
      </div>
    </b-modal>

    <b-row>
      <b-col class="my-1" md="6">
        <p>Showing {{ ((currentPage - 1) * perPage).toLocaleString() }} to {{
      (((currentPage - 1) * perPage) + perPage > totalRows ? totalRows : ((currentPage - 1) * perPage) +
        perPage).toLocaleString()
    }} of {{ parseInt(totalRows).toLocaleString() }} entries</p>
      </b-col>
      <b-col class="my-1 d-flex flex-row-reverse" md="6">
        <b-pagination v-model="currentPage" :per-page="perPage" :total-rows="totalRows" class="my-0"></b-pagination>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import columnSelect from '@/components/custom/tableColumnSelect'
import { Modal } from 'ant-design-vue'
import { cloneRemoteRTBZone } from '@/api/display/remotertbzone'
import perPageSelect from '@/components/custom/PerPageSelect'
import { getRTBZonesShort } from '@/api/display/rtbzone'
import { getPopFeedsShort } from '@/api/display/popfeed'
import { getVideozonesShort } from '@/api/display/videozone'
import { getBannerzonesShort } from '@/api/display/bannerzone'

export default {
  components: {
    columnSelect,
    CreateButton: () => import('@/components/custom/create-button/index.vue'),
    perPageSelect,
  },
  data() {
    return {
      selectedAdvertiser: [],
      selectedUser: [],
      days: [
        {
          title: 'Saturday',
          shortcut: 'sat',
        },
        {
          title: 'Sunday',
          shortcut: 'sun',
        },
        {
          title: 'Monday',
          shortcut: 'mon',
        },
      ],
      selectedDays: ['sun'],
      fields: [
        {
          key: 'actions',
          label: 'Actions',
          show: true,
          headerTitle: 'Actions',
          class: 'text-center',
          thStyle: 'width:120px',
        },
        {
          key: 'Id',
          label: 'Zone ID',
          show: true,
          headerTitle: 'Zone ID',
          sortable: true,
          thStyle: 'width:80px',
          tdClass: 'text-right',
        },
        {
          key: 'Name',
          label: 'Zone Name',
          show: true,
          headerTitle: 'Zone Name',
          sortable: true,
        },
        {
          key: 'AdvertiserName',
          label: 'Advertiser',
          show: true,
          headerTitle: 'Advertiser Name',
          sortable: true,
        },
        {
          key: 'Bannerzones',
          label: 'Banner Zones',
          show: true,
          headerTitle: 'Banner Zones',
          sortable: true,
          tdClass: 'text-center',
          thStyle: 'width: 115px',
        },
        {
          key: 'Videozones',
          label: 'Video Zones',
          show: true,
          headerTitle: 'Video Zones',
          sortable: true,
          tdClass: 'text-center',
          thStyle: 'width: 105px',
        },
        {
          key: 'Rtbzones',
          label: 'RTB Zones',
          show: true,
          headerTitle: 'RTB Zones',
          sortable: true,
          tdClass: 'text-center',
          thStyle: 'width: 90px',
        },
        {
          key: 'Popfeeds',
          label: 'Pop Feeds',
          show: true,
          headerTitle: 'Pop Feeds',
          sortable: true,
          tdClass: 'text-center',
          thStyle: 'width: 90px',
        },
        {
          key: 'BannerEnabled',
          label: 'Banner',
          show: true,
          headerTitle: 'Banner Enabled',
          sortable: false,
          class: 'text-center',
          thStyle: 'width:60px',
        },
        {
          key: 'VideoEnabled',
          label: 'Video',
          show: true,
          headerTitle: 'Video Enabled',
          sortable: false,
          class: 'text-center',
          thStyle: 'width:50px',
        },
        {
          key: 'AudioEnabled',
          label: 'Audio',
          show: true,
          headerTitle: 'Audio Enabled',
          sortable: false,
          class: 'text-center',
          thStyle: 'width:50px',
        },
        {
          key: 'NativeEnabled',
          label: 'Native',
          show: true,
          headerTitle: 'Native Enabled',
          sortable: false,
          class: 'text-center',
          thStyle: 'width:50px',
        },
        {
          key: 'PopEnabled',
          label: 'Pop',
          show: true,
          headerTitle: 'Pop Enabled',
          sortable: false,
          class: 'text-center',
          thStyle: 'width:50px',
        },
        {
          key: 'WebEnabled',
          label: 'Web',
          show: true,
          headerTitle: 'Web Enabled',
          sortable: false,
          class: 'text-center',
          thStyle: 'width:50px',
        },
        {
          key: 'AppEnabled',
          label: 'App',
          show: true,
          headerTitle: 'App Enabled',
          sortable: false,
          class: 'text-center',
          thStyle: 'width:50px',
        },
        {
          key: 'MaxBidfloor',
          label: 'Max. Bidfloor',
          headerTitle: 'Max. Bidfloor',
          show: true,
          sortable: true,
          thClass: 'text-center',
          tdClass: 'text-md-right text-center',
          formatter: '$formatCurrency',
          thStyle: 'width:110px',
        },
        {
          key: 'MinTmax',
          label: 'Min. Tmax',
          headerTitle: 'Min. Tmax',
          show: true,
          sortable: true,
          class: 'text-center',
          thStyle: 'width:90px',
          tdClass: 'text-md-right text-center',
        },
        {
          key: 'MaxIvt',
          label: 'Max. IVT',
          headerTitle: 'Max. IVT',
          show: false,
          sortable: true,
          class: 'text-center',
          thStyle: 'width:90px',
          tdClass: 'text-md-right text-center',
        },
        {
          key: 'MaxSivt',
          label: 'Max. SIVT',
          headerTitle: 'Max. SIVT',
          show: false,
          sortable: true,
          class: 'text-center',
          thStyle: 'width:100px',
          tdClass: 'text-md-right text-center',
        },
        {
          key: 'RequestsDailyLimit',
          label: 'Requests Daily Limit',
          headerTitle: 'Requests Daily Limit',
          show: false,
          sortable: true,
          thClass: 'text-center',
          tdClass: 'text-right',
          formatter: '$formatNumber',
          thStyle: 'width:160px',
          summed: false,
        },
        {
          key: 'BidsDailyLimit',
          label: 'Bids Daily Limit',
          headerTitle: 'Bids Daily Limit',
          show: false,
          sortable: true,
          thClass: 'text-center',
          tdClass: 'text-right',
          formatter: '$formatNumber',
          thStyle: 'width:130px',
          summed: false,
        },
        {
          key: 'ImpressionsDailyLimit',
          label: 'Imp. Daily Limit',
          headerTitle: 'Impressions Daily Limit',
          show: false,
          sortable: true,
          thClass: 'text-center',
          tdClass: 'text-right',
          formatter: '$formatNumber',
          thStyle: 'width:130px',
          summed: false,
        },
        {
          key: 'RevenueDailyLimit',
          label: 'Revenue Daily Limit',
          headerTitle: 'Revenue Daily Limit',
          show: false,
          sortable: true,
          thClass: 'text-center',
          tdClass: 'text-right',
          formatter: '$formatCurrency',
          thStyle: 'width:150px',
          summed: false,
        },
        {
          key: 'ZoneUrl',
          label: 'Zone URL',
          show: false,
          headerTitle: 'Zone URL',
          sortable: true,
        },
        {
          key: 'Description',
          label: 'Description',
          show: false,
          headerTitle: 'Description',
          sortable: true,
        },
        {
          key: 'SeatListMode',
          label: 'Seat List Mode',
          show: true,
          headerTitle: 'Seat List Mode',
          sortable: false,
          class: 'text-center',
          thStyle: 'width:110px',
        },
        {
          key: 'ImpressionTrackingMethod',
          label: 'ITM',
          show: false,
          headerTitle: 'Impression Tracking Method',
          sortable: true,
          thStyle: 'width:120px',
        },
        {
          key: 'MaxQps',
          label: 'Max. QPS',
          headerTitle: 'Maximum queries per second',
          show: true,
          sortable: true,
          thClass: 'text-center',
          tdClass: 'text-right',
          thStyle: 'width:90px',
          summed: false,
        },
        {
          key: 'AutoOptimize',
          label: 'AutoOpt',
          show: true,
          headerTitle: 'AutoOpt',
          sortable: true,
          class: 'text-center',
          thStyle: 'width:85px',
        },
        {
          key: 'Qps',
          label: 'QPS',
          show: true,
          headerTitle: 'Queries per second',
          sortable: true,
          thClass: 'text-center',
          tdClass: 'text-right',
          thStyle: 'width:70px',
        },
        {
          key: 'AvgRequestTimeMs',
          label: 'Avg. Time',
          headerTitle: 'Daily average request time in milliseconds',
          show: true,
          sortable: true,
          class: 'text-center',
          thStyle: 'width:100px',
          tdClass: 'text-md-right text-center',
        },
        {
          key: 'AccManagerId',
          label: 'Acc. Mngr. ID',
          show: false,
          headerTitle: 'Account Manager ID',
          sortable: true,
          thStyle: 'width:110px',
          tdClass: 'text-right',
        },
        {
          key: 'AccManagerName',
          label: 'Account Manager',
          show: true,
          headerTitle: 'Account Manager Name',
          sortable: true,
        },
        {
          key: 'StatusString',
          label: 'Active',
          show: true,
          class: 'text-center',
          thStyle: 'width:80px',
        },
      ],
      rtbzones: null,
      popfeeds: null,
      videozones: null,
      bannerzones: null,
      impressionTrackingMethod: ['nurl', 'burl', 'Markup Display'],
      currentPage: 1,
      perPage: 50,
      pageOptions: [20, 50, 100, 200],
      sortBy: null,
      sortDesc: false,
      sortDirection: 'desc',
      filter: null,
      infoModal: {
        id: 'info-modal',
        title: '',
        content: '',
      },
      modal: {
        zoneUrl: '',
        reportsByDate: '',
        reportsBySubid: '',
        reportsByCountry: '',
      },
    }
  },
  computed: {
    ...mapState(['remotertbzone', 'advertiser', 'settings', 'user', 'rtbzone']),
    ...mapGetters('remotertbzone', ['getRemoteRTBZones', 'getActiveRemoteRTBZones']),
    showActive: {
      get: function () {
        return this.settings.showActiveRemoteRTBZones
      },
      set: function (value) {
        this.$store.commit('CHANGE_SETTING', {
          setting: 'showActiveRemoteRTBZones',
          value,
        })
      },
    },
    showRemoteRTBZones() {
      return this.showActive ? this.getActiveRemoteRTBZones : this.getRemoteRTBZones
    },
    filteredFields() {
      return this.fields.filter(c => c.show)
    },
    totalRows() {
      return this.showRemoteRTBZones.length
    },
  },
  methods: {
    showModal(item) {
      this.$bvModal.show('bv-modal-example')
      this.modal.zoneUrl = item.ZoneUrl
      this.modal.reportsByDate = item.StatsByDate
      this.modal.reportsBySubid = item.StatsBySubid
      this.modal.reportsByCountry = item.StatsByCountry
    },
    selectUrl(e) {
      e.target.select()
      e.stopPropagation()
    },
    copyUrl(link) {
      navigator.clipboard.writeText(link)
    },
    sortCompare(a, b, key) {
      if (key === 'Rtbzones' || key === 'Videozones' || key === 'Bannerzones') {
        const lenA = Array.isArray(a[key]) ? a[key].length : 0
        const lenB = Array.isArray(b[key]) ? b[key].length : 0
        return lenA - lenB
      }
      return a[key] > b[key] ? 1 : (a[key] < b[key] ? -1 : 0)
    },
    getAdvertisers() {
      window.localStorage.setItem('remote-rtb-zone-advertiser-key', JSON.stringify(this.selectedAdvertiser))
      this.$store.dispatch('remotertbzone/LOAD_ZONES_FOR_ADVERTISER', { AdvertiserId: this.selectedAdvertiser })
    },
    getFilteredZones() {
      if (this.selectedAdvertiser !== []) {
        window.localStorage.setItem('remote-rtb-zone-advertiser-key', JSON.stringify(this.selectedAdvertiser))
      }
      if (this.selectedUser !== []) {
        window.localStorage.setItem('remote-rtb-zone-user-key', JSON.stringify(this.selectedUser))
      }
      this.$store.dispatch('remotertbzone/LOAD_ZONES_FOR_USER_ADV', { AccManagerId: this.selectedUser, AdvertiserId: this.selectedAdvertiser })
    },
    getRemoteRTBZone(id) {
      cloneRemoteRTBZone(id).then(response => {
        if (response) {
          // this.$router.push('/display/remotertbzone/update/' + response.Remotertbzone.Id)
          this.$router.push({
            name: 'remotertbzone-update',
            params: { id: response.Remotertbzone.Id },
          }).catch(() => {
          })
          this.$notification.success({
            message: 'Remote RTB Zone cloned',
            description: `Remote RTB Zone: ${response.Remotertbzone.Name} has been successfully cloned.`,
          })
        }
      }).catch(error => console.log(error))
    },
    copyRemoteRTBZone(id, ad) {
      Modal.confirm({
        title: 'Are you sure you want to clone Remote RTB Zone?',
        content: h => <div>{status} Clone Remote RTB Zone: <strong>{ad.Name}</strong>?</div>,
        onOk: function () {
          this.getRemoteRTBZone(id)
        }.bind(this),
      })
    },
    rowClass(item, type) {
      if (item != null && item.Status === 1) return 'text-muted'
    },
    editRemoteRTBZone(id) {
      if (this.selectedAdvertiser && this.selectedAdvertiser !== []) {
        window.localStorage.setItem('remote-rtb-zone-advertiser-key', JSON.stringify(this.selectedAdvertiser))
        if (window.localStorage.getItem('remote-rtb-zone-user-key')) {
          window.localStorage.setItem('remote-rtb-zone-user-key', JSON.stringify(this.selectedUser))
        }
      }
      // this.$router.push('/display/remotertbzone/update/' + id)
      this.$router.push({ name: 'remotertbzone-update', params: { id: id } }).catch(() => { })
    },
    toggleRemoteRTBZoneStatus(remotertbzone) {
      const status = remotertbzone.Status === 0 ? 'Deactivate' : 'Activate'
      Modal.confirm({
        title: 'Are you sure you want to change Remote RTB Zone status?',
        content: h => <div>{status} Remote RTB Zone <strong>{remotertbzone.Name}</strong>?</div>,
        onOk: function () {
          const newStatus = remotertbzone.Status === 0 ? 1 : 0
          this.$store.dispatch('remotertbzone/CHANGE_STATUS', {
            id: remotertbzone.Id,
            status: newStatus,
          })
        }.bind(this),
      })
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
  },
  created() {
    if ((window.localStorage.getItem('remote-rtb-zone-advertiser-key')) !== null || window.localStorage.getItem('remote-rtb-zone-user-key') !== null) {
      this.$store.dispatch('remotertbzone/LOAD_ZONES_FOR_USER_ADV', { AdvertiserId: parseInt(window.localStorage.getItem('remote-rtb-zone-advertiser-key')) || [], AccManagerId: parseInt(window.localStorage.getItem('remote-rtb-zone-user-key')) || [] })
    } else {
      this.$store.dispatch('remotertbzone/LOAD_ZONES')
    }
    this.$store.dispatch('advertiser/LOAD_ADVERTISERS_SHORT')
    getRTBZonesShort()
      .then(res => {
        this.rtbzones = res
      })
    getPopFeedsShort()
      .then(res => {
        this.popfeeds = res
      })
    getVideozonesShort()
      .then(res => {
        this.videozones = res
      })
    getBannerzonesShort()
      .then(res => {
        this.bannerzones = res
      })
    this.$store.dispatch('user/LOAD_USERS')
      .then(() => {
        if (window.localStorage.getItem('remote-rtb-zone-advertiser-key')) {
          const filterAdvertiser = parseInt(window.localStorage.getItem('remote-rtb-zone-advertiser-key'))
          if (filterAdvertiser !== [] && !isNaN(filterAdvertiser)) {
            this.selectedAdvertiser = filterAdvertiser
          }
          window.localStorage.removeItem('remote-rtb-zone-advertiser-key')
        }
        if (window.localStorage.getItem('remote-rtb-zone-user-key')) {
          const filterUser = parseInt(window.localStorage.getItem('remote-rtb-zone-user-key'))
          if (filterUser !== [] && filterUser) {
            this.selectedUser = filterUser
          }
          window.localStorage.removeItem('remote-rtb-zone-user-key')
        }
      })
  },
  // mounted() {
  //   if (window.localStorage.getItem('remote-rtb-zone-advertiser-key')) {
  //     const filterAdvertiser = parseInt(window.localStorage.getItem('remote-rtb-zone-advertiser-key'))
  //     this.selectedAdvertiser = filterAdvertiser
  //     window.localStorage.removeItem('remote-rtb-zone-advertiser-key')
  //   }
  // },
}
</script>

<style scoped>
.zone-list {
  display: flex;
  flex-direction: column;
}
</style>
